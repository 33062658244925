@import "./variables";

* {
  box-sizing: border-box;
}

.navigation {
  color: var(--toolbar-colors-text);
  background-color: var(--toolbar-colors-background);
  overflow: hidden;
  width: $navigation-width;
  height: 100%;
  user-select: none;

  .navigation-entry-item {
    @apply m-2 p-3 rounded cursor-pointer flex flex-row gap-4;
  }

  .mat-mdc-list-item,
  .mat-icon,
  .mat-list-item-content,
  .mat-mdc-list-base .mat-list-item,
  .mat-expansion-panel,
  .mat-panel,
  .mat-expansion-panel-header-title,
  .mat-expansion-indicator {
    color: var(--toolbar-colors-text);
    font-size: 14px;
    height: 44px;
  }

  .header {
    text-align: center;
    margin-bottom: 20px;
  }

  .title {
    width: $navigation-width;
    padding: 0 20px;
    font-weight: 200;
    color: var(--toolbar-colors-text);
    text-align: center;
    font-size: 24px;
  }

  .small-muted {
    font-size: 10px;
    color: #b8b8b8;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 400;
  }
}

// levels
.navigation-entry-active .caret {
  background-color: red;
}

mat-nav-list {
  padding-top: 50px !important;
}

.navigation .mat-nav-list .navigation-entry-item-1 .mat-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.navigation-entry-item-2 {
  background-color: var(--toolbar-colors-backgrounds-background-lighter);
}

.mat-mdc-list-item.navigation-entry-item-2 {
  padding-left: 20px;
  box-sizing: border-box;
}

.navigation-entry-item-3 {
  background-color: #627782;
}

.navigation-entry-item:not(.navigation-entry-active) {
  border-left: 5px solid transparent;
}

.navigation-entry-item-1.navigation-entry-active,
.navigation-entry-item-1:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  border-left: 5px solid transparent;
}

.navigation-entry-item-2.navigation-entry-active {
  border-left: 5px solid #348abe;
}
