@use "@angular/material" as mat;
// Import material theming functions

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$stack-primary: mat.m2-define-palette(mat.$m2-blue-gray-palette, 600, 400, 900);
$stack-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, 600, 400, 900);
$stack-warn: mat.m2-define-palette(mat.$m2-red-palette, 700, 400, 900);

$stack-gray: mat.m2-define-palette(mat.$m2-grey-palette, 100, 600, 800);
$stack-bluegray: mat.m2-define-palette(mat.$m2-blue-gray-palette, 300, 600, 900);

// SCSS Variables
$primary: mat.m2-get-color-from-palette($stack-primary);
$accent: mat.m2-get-color-from-palette($stack-accent);
$warn: mat.m2-get-color-from-palette($stack-warn);

$navigation-width: 280px;
