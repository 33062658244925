@import "@shared/all";
@import "./variables";
@import "./helpers";
@import "./animations";
@import "./notifications";
@import "./thirdparty-hacks";
@import "./navigation";
@import "./error-messages";
@import "./dashboard";
@import "./no-print";
@import "./text";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./tailwind.base";

html {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0px;
}

.app-container .company-avatar {
  height: 180px;
  color: rgba(255, 255, 255, 0.65);
}

mat-sidenav .scroll-content {
  padding-bottom: 16px;
}

.scroll-content {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.field-container-label {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.upload-thumb {
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
}

// Fullscreen Layout Component overrides
.fullscreen-layout-container {
  background-image: url("../../assets/images/bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
}

// Buttons
.mat-mdc-button {
  text-transform: uppercase;
}

@media (max-width: 959px) {
  .mat-mdc-raised-button {
    width: 100%;
  }
}

// Cards
.backoffice-panel .mat-mdc-card-title {
  margin-bottom: 6px !important;
}

.mat-mdc-card-avatar {
  padding-top: 5px;
}

.mat-spinner-small {
  margin: 0px auto;
  width: 50px !important;
  height: 50px !important;
}

.mat-spinner-small svg {
  width: 50px !important;
  height: 50px !important;
}

.mat-spinner-tiny {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

.mat-spinner-tiny svg {
  width: 20px !important;
  height: 20px !important;
}

.mat-drawer-side {
  border-right: none;
}

.mat-mdc-list-base {
  padding-top: 0px !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

// Small tabs
div.small-flags-tab-container {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label,
  .mat-tab-label-active {
    min-width: 0 !important;
    width: 70px;
    padding: 10px !important;
    margin: 0 10px 0 10px !important;
  }
}

// OLD STUFF
.mat-drawer-container {
  height: 100%;
  background-color: #ededed !important;
}

.mainContent {
  height: 100%;
}

.backoffice-main-content {
  height: calc(100% - 64px);
  overflow: auto;
}

.navbar-header {
  width: 280px;
  align-items: center;
  transition: padding 200ms ease;
  background-color: var(--toolbar-colors-background);
  color: white;
}

.mat-drawer.mat-sidenav.detail-view-container button.btn-block {
  width: 100%;
  margin-top: 20px;
}

// Sidebar
.app-container > .mat-drawer {
  min-width: 280px;
  width: 280px;
  border-right: none !important;
}

.scrollbar-track {
  background: transparent !important;
  width: 4px !important;
  margin-right: 2px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.mat-mdc-header-row {
  background-color: #fbfbfb !important;
}

.mat-mdc-card .mat-divider-horizontal {
  position: relative !important;
}

.mat-mdc-paginator.marginless-paginator-range-label {
  .mat-mdc-paginator-range-label {
    margin: 0;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button.no-hover:hover .mat-button-focus-overlay {
  opacity: 0;
}

// cms pages and release notes
cms-page {
  img {
    max-width: 100%;
  }
}

.mat-mdc-standard-chip {
  height: auto !important;
}
