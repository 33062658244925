/** Remove when material is gone **/

@use "@angular/material" as mat;

@include mat.core();

$smallstack-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$smallstack-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$smallstack-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $smallstack-primary,
      accent: $smallstack-accent
    )
  )
);

@include mat.all-component-themes($smallstack-theme);

:root {
  --mat-table-background-color: transparent;
}

// if flag component is used inside mat-icon component, reduce the flag's svg size
.mat-icon .flag-icon {
  width: 16px;
  height: 16px;
  padding: 0px;
  padding-bottom: 6px;
}

.header-menu-backdrop.mat-mdc-menu-panel {
  background-color: var(--header-colors-background) !important;
  color: var(--toolbar-colors-text) !important;
}

.mat-outline {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 5px;

  & > mat-label {
    position: absolute;
    top: -15px;
    left: 5px;
    background-color: white;
    padding: 1px 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  & > mat-hint {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.no-padding {
  padding: 0px;
}

.mat-outline:hover {
  border: 1px solid #c7c7c7;
}

.mat-outline.no-hover:hover {
  border: 1px solid #f0f0f0;
}

.smallstack-schema-form-table .mat-outline {
  margin-top: 0px;
  margin-bottom: 0px;
}

// Horizontal scrolling for tables

.scrolling-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .mat-mdc-table {
    width: 100%;
    max-width: 100%;
    display: table;
    // border-collapse: collapse;

    /*
    .mat-table-sticky:first-of-type {
      box-shadow: -2px 10px 6px 2px rgba(0, 0, 0, 0.12);
    }
    .mat-table-sticky:last-of-type {
      box-shadow: 2px 10px 6px 2px rgba(0, 0, 0, 0.12);
    } */
  }

  .mat-mdc-row,
  .mat-mdc-header-row {
    display: table-row;
  }

  .mat-mdc-cell.flex-tablecell,
  .mat-mdc-header-cell.flex-tablecell {
    display: flex !important;
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    word-wrap: initial;
    display: table-cell;
    line-break: unset;
    padding: 0px 5px;
    // width: 100%;
    // white-space: nowrap;
    overflow: hidden;
  }

  .mat-mdc-cell:first-of-type,
  .mat-mdc-header-cell:first-of-type {
    padding-left: 24px;
  }
  .mat-mdc-cell:last-of-type,
  .mat-mdc-header-cell:last-of-type {
    padding-right: 24px;
    justify-content: flex-end;
  }
}

.compact-form-field-row {
  button {
    margin: 0.25em 0px !important;
  }

  .mat-mdc-outlined-button {
    height: 52px !important;
  }
}

.mat-mdc-radio-button {
  max-width: 100%;
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label-content {
    width: calc(100% - 28px);
  }
}

// Buttons
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px !important;
}
button {
  text-transform: uppercase;
}

.mat-mdc-form-field-hint {
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.small-input.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
  font-size: 14px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.small-input.mat-form-field-appearance-outline .mat-form-field-infix .mat-select-arrow {
  margin-top: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.small-input.mat-form-field-appearance-outline .mat-select-empty + .mat-form-field-label-wrapper .mat-form-field-label {
  margin-top: -0.75em;
}

// increase mat-input float label font size
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
mat-form-field.mat-form-field-should-float label {
  font-size: 16px;
}

// Table Things
table th button.mat-mdc-icon-button,
.mat-mdc-table .mat-mdc-header-row button.mat-mdc-icon-button {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

table th button.mat-mdc-icon-button .mat-icon,
.mat-mdc-table .mat-mdc-header-row button.mat-mdc-icon-button .mat-icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-mdc-menu-panel {
  min-height: 5px !important;
}

@media screen and (max-width: "959px") {
  .mat-bottom-sheet-container {
    padding: 16px !important;
  }
}

// Mat Chips
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
button.mat-chip-remove.mat-chip-trailing-icon {
  position: relative;
  bottom: 2px;
}

// matPrefix/Suffix in form fields
[matPrefix] smallstack-flag {
  top: -5px;
  position: relative;
}

/* FIX VIRTUAL SCROLL */
smallstack-worklog-widget cdk-virtual-scroll-viewport {
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
    overflow-x: auto;
  }
}
