// SIMPLE CENTERED ROTATION
.rotate-center {
  -webkit-animation: rotate-center 1s ease-in-out infinite both;
  animation: rotate-center 1s ease-in-out infinite both;
}

// Rotation with easeOutSine
.rotate-center-acc {
  -webkit-animation: rotate-center 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
  animation: rotate-center 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
}

// KEYFRAMES
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
