@mixin max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

.error-message {
  border-radius: 4px;
  margin: 2em;
  padding: 2em;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success {
  border: 1px solid #d4ffa8;
  background-color: #ecffd9;
}

.failure {
  border: 1px solid #fce4c2;
  background-color: #fff4e5;
}

.error-message > h1 {
  margin-top: 0;
  font-size: 18px;
}
