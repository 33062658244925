@import "./variables";

// On Page notification boxes
.box-warning {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fff4e5;
  margin: 2em 0em;
  padding: 1em;
}

.box-warning::before {
  content: "";
  display: block;
  background: url(/assets/images/icons/warning-48.png) no-repeat;
  width: 1.2em;
  height: 1.2em;
  float: left;
  background-size: contain;
  margin-right: 8px;
}

.box-help {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin: 2em 0em;
  padding: 1em;
}

.box-help::before {
  content: "";
  display: block;
  background: url(/assets/images/icons/help-48.png) no-repeat;
  width: 1.2em;
  height: 1.2em;
  float: left;
  background-size: contain;
  margin-right: 8px;
}

.snackbar-info {
  color: white !important;
}

.snackbar-error {
  background-color: oklch(var(--er)) !important;
  color: white !important;
}

.snackbar-warn {
  background-color: oklch(var(--wa)) !important;
  color: white !important;
}
