button {
  text-transform: uppercase !important;
}

*:hover {
  scrollbar-color: unset !important;
}

* {
  scrollbar-color: unset !important;
}
