// Helper classes
.full-width {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.no-link {
  text-decoration: none;
}

.text-italic {
  font-style: italic;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-muted-title {
  color: gray;
  font-size: smaller;
  text-align: center;
  padding-top: 5px;
}
.text-muted {
  color: gray;
  font-size: smaller;
}
.no-wrap {
  white-space: nowrap;
}
.mat-headline-6 + .text-muted {
  margin-top: -16px;
  margin-bottom: 8px;
}
.table-actions-align-end {
  text-align: right !important;
}
.dark {
  color: black;
}
.light {
  color: white;
}
