@media (max-width: 599px) {
  .mat-mdc-dialog-container {
    position: fixed !important;
    top: unset !important;
    bottom: calc(0px + env(safe-area-inset-bottom)) !important;
    left: calc(0px + env(safe-area-inset-left)) !important;
    right: calc(0px + env(safe-area-inset-right)) !important;
    height: auto !important;
    max-height: 90% !important;
    max-width: unset !important;
  }

  .mat-mdc-dialog-surface {
    border-radius: 0px !important;
    max-width: unset !important;
  }

  .input-dropdown {
    width: 100% !important;
    position: fixed !important;
    top: unset !important;
    bottom: calc(0px + env(safe-area-inset-bottom)) !important;
    left: calc(0px + env(safe-area-inset-left)) !important;
    right: calc(0px + env(safe-area-inset-right)) !important;
    height: auto !important;
    max-height: 90% !important;
    max-width: unset !important;
  }
}

@media (max-width: 959px) {
  .mat-mdc-dialog-container {
    border-radius: 0px !important;
  }
}
