.dashboard h1 {
  font-size: 72px;
}

.dashboard .autoscaling-text {
  font-size: 22px;
}

.dashboard .widget {
  height: 100%;
}
