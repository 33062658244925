/**
* Remove unnecessary objects for print mode.
*/

@media print {
  .no-print,
  .no-print *,
  .mat-mdc-paginator-page-size,
  .mat-mdc-paginator-navigation-first,
  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next,
  .mat-mdc-paginator-navigation-last,
  .mat-mdc-icon-button,
  .mat-mdc-tooltip-trigger {
    display: none !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
  mat-card {
    box-shadow: none !important;
  }

  .app-container > .mat-drawer {
    min-width: 0px !important;
    width: 0px !important;
  }

  .app-container .mat-drawer-content {
    margin-left: 0px !important;
  }
}
